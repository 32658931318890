<script>
import { mapActions, mapMutations, mapGetters } from "vuex";

import Footer from "@/layouts/Footer.vue";
//COMPONENTS
import Modal from "@/components/Modal.vue";
import ReactionButton from "@/components/ReactionButton.vue";
import SecondaryButton from "@/components/SecondaryButton.vue";
import {reactNotification} from '../../api/notifications/get'

export default {
	props: {
		isTour: {
			required: false,
			type: Boolean,
		},
	},
	components: {
		SecondaryButton,
		Footer,
		Modal,
		ReactionButton
	},
	data() {
		return {
			messages: {
				vote: [],
				reaction: []
			},
			modal: {
				title: null,
				work_position: null,
				message: null,
				comment: null,
				showComment: false,
				show: false,
				gone: false,
				reaction: null,
				type: '',
				original: null
			},
			modalReaction: {
				show: false,
				gone: false,
			},
			modalMore: {
				show: false,
				gone: false,
			},
			selectedReaction: null,
			selectedNotification: null,
			comment: ''
		};
	},
	async created() {
		this.setLoading(false);
		await this.loadMessages({type: 'vote', size: 5, page: 1});
		await this.getReactions();
	},
	computed: {
		...mapGetters({
			readNotifications: "getNotifications/readNotifications",
			//ERRORS
			readNotificationsError: "getNotifications/readError",
			readHasReadError: "hasReadNotification/readError",
			readReactions: "getReactions/readReactions",
			readUser:  'auth/readUser',
			readToken: 'loginStore/readToken',
			readCountVote: "getNotifications/readCountVote",
			readCountReaction: "getNotifications/readCountReaction",
		}),
	},
	methods: {
		...mapMutations({
			//LOADING
			setLoading: "loading/SET_LOADING",
			setNotificationsId: "getNotifications/SET_ID",
			setHasReadId: "hasReadNotification/SET_ID",
			//ERRORS
			setNotificationsError: "getNotifications/SET_ERROR",
			setHasReadError: "hasReadNotification/SET_ERROR",
		}),
		...mapActions({
			setHasRead: "hasReadNotification/hasRead",
			getNotifications: "getNotifications/getNotifications",
			getReactions: 'getReactions/getReactions'
		}),
		toggleModal() {
			this.modal.show = !this.modal.show;
		},
		getImage(image) {
			return `${require(`@/assets/images/views/VOTACION/INICIO/${image}`)}`;
		},
		selectMessage(message) {
			if (message?.is_unread) {
				this.modal.title = message?.title;
				this.modal.work_position = message?.emitter.work_position;
				this.modal.message = message?.content;
				this.modal.showComment= false
				this.modal.comment = message?.comment;
				this.modal.type = message?.type
				this.modal.reaction = this.readReactions.find(r => r.id == message.reaction_id)
				this.modal.original = message
				this.toggleModal();
				// this.markRead(key)
			}
		},
		markRead(key) {
			this.cleanErrors();
			this.setHasReadId(this.messages[key]?.id);
			this.setHasRead();
		},
		
		loadMessages(loading = true, params = {type: 'vote'}) {
			let vm = this
			vm.setLoading(loading);
			vm.cleanErrors();
			vm.setNotificationsId(null);
			vm.getNotifications(params)
				.then((result) => {
					if (result) {
						// this.loadEmitters(this.readNotifications);
						vm.$set(vm.messages, params.type, vm.readNotifications(params.type))
						let votes = vm.readNotifications(params.type)
						console.log(votes)
						let message = votes.items.find(n => n.is_unread)
						vm.selectMessage(message);
					} else {
						vm.messages[params.type] = [];
					}
				})
				.finally(() => {
					vm.setLoading(false);
				});
		},
		begin() {
			return !this.isTour ? this.$router.push({ name: "Reconocer" }) : null;
		},
		getReactionImage(image) {
			return `${require(`@/assets/images/components/reactions/${image}`)}`;
		},
		cleanErrors() {
			this.setHasReadError(null);
			this.setNotificationsError(null);
		},
		hideModal(modal = 'modal') {
			this[modal].gone = true;
			this[modal].show = false;
		},
		showComment() {
			if (this.modal.comment) this.modal.showComment = true;
		},
		hideComment() {
			this.modal.showComment = false;
		},
		emitReaction(reaction, notification) {

			this.selectedReaction = reaction
			this.selectedNotification = notification
			if(notification.emitter_id != this.readUser.id) {
				if (notification.is_unread) {
					this.setHasReadId(notification.id);
					this.setHasRead()
				}
			}
			this.showModal('modalReaction')
		},
		async submitReaction() {
			this.setLoading(true);
			try {
				// this.modal.showComment = false
				this.selectedNotification.reaction_id = this.selectedReaction.id
				let result = await reactNotification(this.readToken, {parent_id: this.selectedNotification.id, reaction_id: this.selectedReaction.id, comment: this.comment})
				this.modal.original.reaction_id = this.selectedReaction.id
				this.selectedNotification = {}
				this.selectedReaction = {}
				this.comment = ''
				this.setLoading(false);
				this.hideModal('modalReaction')
				this.hideModal('modal')
				if(this.readCountVote > 1) {
					this.showModal('modalMore')
				}
			} catch (error) {
			}
			// this.loadMessages( true, {type: 'vote'})
		},
		showModal(modal = "modal") {
			this[modal].gone = false;
			this[modal].show = true;
		},
		
		
	},
};
</script>
<template>
	<div class="relative h-full flex flex-col justify-center items-center">
		<div class="modal-wrap" v-if="modal.show || modal.showComment">
			<Modal
				buttonText=""
				
			>
				<div class="text-black text-center modal-base modal-active">
					<button  class="absolute right-0 top-n1 rounded-full border  w-5 h-5 z-10	flex justify-center items-center" @click="hideModal('modal')">

						<font-awesome-icon

						icon="fa-solid fa-xmark"

						/>
					</button>
					<span class="b  block text-xl pr-3">{{
						modal.title
					}}</span>
					<span
						class="block text-xl"
						v-capitalizeWords="modal.work_position"
					></span>
					<span
						class="block text-xl"
						v-capitalizeWords="modal.agency"
					></span>
					<hr class="bg-gray h-0.5 my-2 w-full block" />
					<span class="block text-lg py-1">{{ modal.message }}</span>
					<span class="block text-lg p-2 elektra-red">{{modal.comment}}</span>
					<div v-if="modal.type == 'reaction'" class="flex">
						<img :src="getReactionImage(modal.reaction.image)" alt="" class="mx-auto w-20 animtae-smile">
					</div>
					<hr class="mb-2" style="border-color: #cbcbcb;">

					<div class="flex aling items-center">
						<ReactionButton :selected="modal.original.reaction_id || 0" :reactions="readReactions" @change="(payload) => emitReaction(payload, modal.original)"/>
						
						<span class="px-4 py-1 text-sm font-bold whitespace-nowrap text-slate-400	">{{ modal.original.created_at  | moment("DD [de] MMMM YYYY") }}</span>
						<!-- <button @click="hideModal('modal')" class="inline-flex items-center justify-center secondary-button w-auto text-sm text-center text-white b rounded-full px-4 py-2 cursor-pointer gradient-button-gray">Cerrar <font-awesome-icon class="ml-2" -->
<!-- 						
						icon="fa-solid fa-xmark"
						
						/></button> -->
					</div>
					<!-- <div class="pt-2">
						<button class="inline-flex items-center justify-center secondary-button w-auto text-sm text-center text-white b rounded-full px-4 py-2 cursor-pointer gradient-button-gray">Ver más reconocimientos <font-awesome-icon class="ml-2" icon="fa-solid fa-angle-right"/> </button>
					</div> -->
				</div>
			</Modal>
			<Modal
					buttonText="Continuar"
					class="modal-base"
					:class="{ 'modal-active': modal.showComment }"
					@click.native="hideComment()"
				>
					<div class="text-white text-center">
						<span class="b yellow block text-xl"
							>Comentarios adicionales:</span
						>
						<span class="block text-lg py-1">{{ modal.comment }}</span>
					</div>
			</Modal>
		</div>
		<div class="modal-wrap" v-if="modalReaction.show">
			<Modal
				buttonText="Reaccionar"
				@click="submitReaction"
				class="modal-base relative"
				:class="{ 'modal-active': modalReaction.show, 'modal-out': modalReaction.gone }"
			>
			
			
				<div class="text-black text-center relative">
					<button  class="absolute right-0 top-n1 rounded-full border  w-5 h-5 z-10	flex justify-center items-center" @click="hideModal('modalReaction')">

						<font-awesome-icon
						
						icon="fa-solid fa-xmark"
						
						/>
					</button>
					<div v-if="selectedReaction" class="flex">
						<!-- <img :src="getReactionImage(selectedReaction.image)" alt="" class="mx-auto w-20 animtae-smile"> -->
					</div>
					<div class="text-black text-lg b text-center mt-5"
						>¿Deseas agregar algún comentario?</div
					>
					<span class="">(opcional)</span>
					<textarea
						class="w-full flex-grow text-lg black h-48 mt-2 pl-4 pr-8 py-2 text-lg rounded-md bg-white"
						placeholder="Comentarios adicionales"
						v-model="comment"
						maxlength="140"
					/>
					<span class=" mt-2 mb-4"
					><span class="elektra-red">{{ 140 - comment.length }} </span
						>caracteres disponibles</span>
				</div>
			</Modal>
		</div>
		<div class="modal-wrap" v-if="modalMore.show">
			<Modal
				buttonText=""
				class="modal-base relative"
				:class="{ 'modal-active': modalMore.show, 'modal-out': modalMore.gone }"
			>
			
			
				<div class=" text-center relative">
					<button  class="absolute right-0 top-n1 rounded-full border  w-5 h-5 z-10	flex justify-center items-center" @click="hideModal('modalMore')">
					<font-awesome-icon

					icon="fa-solid fa-xmark"

					/>
					</button>
					
					<div class="text-lg b text-center mt-5"
						>Tienes {{ readCountVote - 1 }}  {{ readCountVote - 1 > 1 ? 'reconocimientos' : 'reconocimiento'}}  sin leer</div
					>
					<div class="mt-3">
						<router-link v-if="readCountVote - 1 > 1" :to="{name: 'Notificaciones'}" class="inline-flex items-center justify-center secondary-button w-auto text-xl text-center text-white b rounded-full px-4 py-2 cursor-pointer gradient-button-gray">Ver los reconocimientos</router-link>
						<router-link v-else :to="{name: 'Notificaciones'}" class="inline-flex items-center justify-center secondary-button w-auto text-xl text-center text-white b rounded-full px-4 py-2 cursor-pointer gradient-button-gray">Ver el reconocimiento</router-link>
					</div>
				</div>
			</Modal>
		</div>
		<div
			class="relative flex flex-col overlay bg-white mx-auto mt-auto mb-2"
		>
			<img
				class="image mb-auto mx-auto"
				:src="getImage('home_img.png')"
				alt="Trofeo"
			/>
			<p class="px-5 text-center text-3xl mb-3 leading-8 cmf-title">
				<span class="b">Reconoce a tus </span>
				<span class="b elektra-red">compañeros</span>
			</p>
			<p class="px-5 text-center text-xl mb-1">
				que crean
				<span class="b">momentos </span>
				<span class="b ">felices</span> <br> porque son
				protagonistas <br>  de <span class="b elektra-red">Nuestra Cultura</span>.
			</p>
			<div class="mt-auto text-center">
				<secondary-button
					id="empezar"
					class="mb-3"
					text="Empezar"
					@click.native="begin"
				></secondary-button>
				<p class="px-5 text-center text-md">
					<span class="elektra-red">Incidencias:</span> <span class="block">Levanta un botón rojo en Zeus.</span>
				</p>
				<Footer class="mt-auto static"></Footer>
			</div>
		</div>
	</div>
</template>
<style lang="scss" scoped>
.overlay {
	height: 85vh;
	max-height: 640px;
}
.image {
	height: 45vh;
	margin-top: -25%;
}
.animtae-smile{
	animation: smileIn .4s forwards ease-in-out;
	animation-delay: 0.4s;
	opacity: 0;
}

@keyframes smileIn{
  0%{
    opacity: 0;
    transform: scale(0.3) translate3d(0,0,0);
  }
  50%{
    opacity: 0.9;
    transform: scale(1.1);
  }
  80%{
    opacity: 1;
    transform: scale(0.89);
  }
  100%{
    opacity: 1;
    transform: scale(1) translate3d(0,0,0);
  }
}
@keyframes go-in {
	0% {
		transform: translateX(100vw);
	}
	100% {
		transform: translateX(0vw);
	}
}

@keyframes go-out {
	0% {
		transform: translateX(0vw);
	}
	100% {
		transform: translateX(-100vw);
	}
}
.modal-base {
	opacity: 0;
	transform: translateX(100vw);
	background: none;
}
.modal-active {
	opacity: 1;
	animation: go-in 0.4s;
	transform: translateX(0vw);
}
.modal-out {
	opacity: 1;
	animation: go-out 0.4s;
	transform: translateX(-100vw);
}
.top-n1{
	top: -1.5rem;
}
.modal-wrap {
	position: absolute;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	background-color: rgba($color: #4a6c85, $alpha: 0.5);
	z-index: 10000000;
}
.whitespace-nowrap{
	white-space: nowrap;
}
.text-slate-400	{
	color: #60686e;
}
</style>
